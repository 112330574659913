$color__white: #fff;
$color__base: #333333;
$color__base--light: $color__white;
$color__accent: $color-brand-accent;
$color__light-green: $bg-color-brand;
$color__marleen: $color-brand;
$container-xl: 1180px - 22px;

$about__media-url: '/media/cms/about';
$home__media-url: '/media/cms/home';
$shared__media-url: '/media/cms/shared';

$diamond__height--mobile: 8px * 1.5;
$diamond__width--mobile: 15px * 1.5;
$diamond__height--desktop: 8px * 2.5;
$diamond__width--desktop: 15px * 2.5;

$slider-control__size\@mobile: 50px;
$slider-control__size\@desktop: 60px;

$font__size--xxl\@desktop: 40px;
$font__size--xxl\@mobile: 30px;

$font__size--xl\@desktop: 32px;
$font__size--xl\@mobile: 24px;

$font__size--l\@desktop: 24px;
$font__size--l\@mobile: 20px;

$font__size--m\@desktop: 20px;
$font__size--m\@mobile: 17px;

$font__size--s\@desktop: 18px;

$spacer__s\@desktop: 10px;
$spacer__s\@mobile: 7.5px;

$spacer__m\@desktop: 20px;
$spacer__m\@mobile: 15px;

$spacer__l\@desktop: 40px;
$spacer__l\@mobile: 30px;

$spacer__xl\@desktop: 70px;
$spacer__xl\@mobile: 45px;

$spacer__xxl\@desktop: 90px;

$spacer__xxxl\@desktop: 120px;
$spacer__xxxl\@mobile: 70px;

$about-intro__title__font-size: $font__size--xxl\@desktop;
