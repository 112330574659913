.map-check {
    background: white;
    padding: $spacer;

    h3 {
        margin-top: 0;
        font-size: 20px;
        color: $color-brand-accent;
    }

    @include mq($screen-m) {
        width: 380px;
        height: 340px;
        background: url('#{$path-images}cms/map-check-back.svg') no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        z-index: $z-index-normal;
        padding: 50px 25px 28px 35px;
        text-align: center;
        right: $spacer;
        top: 30px;

        h3 {
            font-size: 20px;
            color: $color-brand-accent;
            font-weight: bold;
            margin-top: 20px;
        }

        p.subtitle {
            font-size: 19px;
            font-style: italic;
            color: $color-brand-accent;
            line-height: 28px;
        }

        ul.form-list {
            text-align: left;
        }
    }

    @include mq($screen-l) {
        top: 40px;
        right: $spacer--large;
    }

    @include mq($screen-xl) {
        left: 50%;
        transform: translateX(170px);
        top: 60px;
    }
}

.map-area-name {
    position: absolute;
    background: #ffffffdd;
    color: #000;
    padding: calc($spacer--extra-small / 2) $spacer;
    border: 2px solid $color-brand;
    border-radius: 5px;
    cursor: default;
    left: $spacer--extra-small;
    top: $spacer--extra-small;

    @include mq($screen-m) {
        bottom: $spacer--extra-small;
        top: auto;
    }
    @include mq($screen-xl) {
        left: 50%;
        transform: translateX(-573px);
    }
    @include mq($screen-xxl) {
        transform: translateX(-573px);
    }
}
