.cms-page-view.cms-over-marleen {
    @include mq($max-screen: $screen-l) {
        #maincontent {
            margin: 0;
        }
    }
}

.about-intro {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 480px;
    justify-content: flex-end;
    background: url('#{$about__media-url}/intro-marleen.jpg') top center no-repeat;
    background-size: cover;
    padding: 0 $spacer__l\@mobile 0 $spacer__m\@mobile;
    margin: 0 0 $spacer__xxxl\@mobile;

    .webp & {
        background-image: url('#{$about__media-url}/intro-marleen.webp');
    }

    &::before {
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        content: '';
        position: absolute;
        background: linear-gradient(30deg, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%);
        background-size: 100% 100%;
    }

    &__title, h2 {
        @include reset-h2();

        text-align: left;
        font-size: 38px;
        font-weight: normal;
        color: $color__base--light;
        line-height: 1.3em;
        text-shadow: 1px 1px 5px #00000044;
        max-width: 80%;
        min-width: 280px;
        margin-bottom: $spacer__m\@mobile;
    }

    &__text {
        font-size: $font__size--m\@mobile * 1.1;
        color: $color__base--light;
        line-height: 1.5em;
        text-shadow: 1px 1px 2px #00000044;
        margin-bottom: 40px;
        max-width: 500px;
    }

    &__content {
        z-index: 2;
    }

    @include mq($container-xl + 1) {
        justify-content: center;
        height: 560px;
        background-size: cover;
        // Set the negative margin to the amount that the image needs to exceed the width of the container
        margin: 0 calc(-1 * (100vw - #{$container-xl}) / 2) $spacer__xxl\@desktop;

        &::before {
            background: linear-gradient(30deg, rgba(0,0,0,0.47) 20%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
            background-size: 100% 100%;
            /*
            background: url('#{$about__media-url}/intro-gradient.svg') bottom left no-repeat;
            background-size: 957px 100%;
            */
        }

        &__content {
            width: $container-xl;
            margin: 0 auto;
        }

        &__title, h2 {
            font-size: $font__size--xxl\@desktop;
            margin-bottom: $spacer__m\@desktop;
        }

        &__text {
            font-size: $font__size--m\@desktop;
            width: 480px;
            margin-bottom: 0;
        }
    }

    // Stop scaling at 1920px
    @include mq($screen-hd) {
        margin-left: -1 * calc(($screen-hd - $container-xl) / 2);
        margin-right: -1 * calc(($screen-hd - $container-xl) / 2);
    }

    // Prevent a positive margin
    @include mq($min-screen: $screen-l, $max-screen: $container-xl) {
        margin-left: 0;
        margin-right: 0;
    }
}

.about-checker {
    margin: $spacer__xxxl\@mobile 0;
    overflow: hidden;

    &__title, h2 {
        @include reset-h2();

        font-size: $font__size--xxl\@mobile;
        color: $color__accent;
        font-weight: normal;
        text-align: center;
        margin: 0 0 $spacer__m\@mobile $spacer__m\@mobile;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        // For SwiperJS
        // overflow-x: auto;
        transition-property: transform;
    }

    &__section {
        $_size: 275px;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: $_size;

        &-image {
            object-fit: cover;
            height: $_size;
            width: $_size;
            max-width: none;
        }

        &-text {
            height: $_size;
            display: block;
            padding: $spacer__l\@mobile $spacer__m\@mobile 0;

            h3 {
                color: $color__accent;
                font-size: $font__size--xl\@mobile;
                font-weight: normal;
                margin: 0 0 $spacer__m\@mobile;
            }

            p {
                font-size: $font__size--m\@mobile;
                line-height: 1.4em;
                color: $color__base;
            }
        }

        &--reverse {
            flex-direction: column-reverse;
        }
        &--reverse &-text {
        }
    }

    @include mq($screen-l) {
        margin: $spacer__xxl\@desktop 0;

        &__title, h2 {
            font-size: $font__size--xl\@desktop;
            margin: 0 0 $spacer__m\@desktop 0;
            text-align: center;
        }

        &__wrapper {
            display: block;
            overflow: auto;
        }

        &__section {
            margin-bottom: $spacer__l\@desktop;
            flex-direction: row;
            width: auto;

            &-image {
                width: 50%;
                height: 375px;
            }

            &-text {
                width: 50%;
                height: 375px;
                display: flex;
                padding: $spacer__m\@desktop;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
                background: $color__light-green;

                h3 {
                    color: $color__accent;
                    font-size: $font__size--xl\@desktop;
                    margin-bottom: $spacer__m\@desktop;
                }

                p {
                    font-size: $font__size--m\@desktop;
                    max-width: 70%;
                    text-align: center;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &--reverse {
                flex-direction: row-reverse;
            }
            &--reverse &-text {
                background: $color__white;
            }
        }
    }
}

.about-usps {
    margin: $spacer__xxxl\@mobile 0;
    position: relative;

    &__visual {
        background: url('#{$about__media-url}/usps-visual.jpg') no-repeat center center;
        background-size: cover;
        height: 465px;
        position: relative;
        padding: $spacer__m\@mobile;

        .webp & {
            background: url('#{$about__media-url}/usps-visual.webp') no-repeat center center;
        }

        &::before {
            z-index: 1;
            width: 100%;
            height: 50%;
            left: 0;
            bottom: 0;
            content: '';
            position: absolute;
            background: linear-gradient(180deg, rgba(255,255,255,0) 25%, rgba(0,0,0,0.2) 100%);
            background-size: 100% 100%;
        }
    }

    &__title, h2 {
        @include reset-h2();

        font-size: $font__size--xxl\@mobile;
        font-weight: normal;
        color: white;
        z-index: 2;
        max-width: 90%;
        text-align: center;

        &--visual {
            display: block;
            position: absolute;
            bottom: $spacer__l\@mobile;
        }
        &--list {
            display: none;
            color: $color__accent;
        }
    }

    &__list {
        padding: $spacer__l\@mobile $spacer__m\@mobile;
        background: $color__light-green;
    }

    &__item {
        display: flex;
        margin-bottom: $spacer__l\@mobile;

        &-icon {
            flex: none;
            width: 35px;
            margin-right: 25px;
            align-self: center;
            flex-grow: 0;
            text-align: right;

            img {
                max-width: 100%;
            }
        }

        &-text {
            flex: auto;
            color: $color__base;
        }

        &-title {
            color: $color__accent;
            font-size: $font__size--l\@mobile;
            margin: 0 0 $spacer__s\@mobile 0;
            line-height: 1.3;
            font-weight: normal;
        }
    }

    &__cta {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -30px;

        &-button {
            @include cta-button();
        }
    }

    @include mq($screen-s) { // 560px
        display: flex;
        margin: $spacer__xxxl\@desktop 0;

        &__visual {
            flex-basis: 700px; // flex-grow flex-shrink flex-basis
            width: 490px;
            height: auto;
        }

        &__title, h2 {
            color: $color__accent;
            margin-bottom: $spacer__m\@desktop;
            font-size: $font__size--xl\@desktop;
            text-align: left;

            &--visual {
                display: none;
            }
            &--list {
                display: block;
                margin-bottom: $spacer__l\@desktop;
                min-width: 330px;
            }
        }

        &__list {
            padding: $spacer__l\@desktop $spacer__l\@desktop;
            background: $color__white;
        }

        &__item {
            &-icon {
                align-self: flex-start;
            }
            &-text {
                font-size: $font__size--m\@desktop;
                color: $color__base;
            }
            &-title {
                font-size: $font__size--l\@desktop;
                color: $color__accent;
            }
        }

        &__button {
            left: 356px + $spacer__xxl\@desktop;
            bottom: -30px;
        }
    }

    @include mq($screen-l) {
        &__list {
            padding: $spacer__xxl\@desktop $spacer__xxl\@desktop;
        }
    }
}

.about-combo {
    margin: $spacer__xxxl\@mobile 0;

    &__visual {
        width: 100%;
        max-width: 100%;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    &__text {
        padding: $spacer__l\@mobile $spacer__m\@mobile;
        margin: -70px $spacer__m\@mobile 0;
        background: $color__white;
        position: relative;

        &-title, h2 { // be more specific than the elsewhere defined .cms-page-view h2
            @include reset-h2();

            color: $color__accent;
            font-size: $font__size--xl\@mobile;
            margin: 0 0 $spacer__m\@mobile;
            font-weight: normal;
            line-height: 1.3em;
            text-align: center;
        }

        p {
            font-size: $font__size--m\@mobile;
            color: $color__base;
            line-height: 1.7em;
            text-align: center;
        }
    }

    @include mq($screen-l) {
        display: flex;
        margin: $spacer__xxxl\@desktop 0;
        flex-direction: row-reverse;

        &__visual {
            flex: none;
            width: 665px;
        }
        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 70px;
            margin: 0;

            &-title, h2 {
                font-size: $font__size--xl\@desktop;
                text-align: left;
            }
            p {
                font-size: $font__size--m\@desktop;
                text-align: left;
            }
        }
    }
}

.about-crew {
    $outdent: 60px;

    margin: $spacer__xxxl\@mobile 0 $spacer__xxxl\@mobile $spacer__m\@mobile;
    position: relative;

    &__title, h2 {
        @include reset-h2();

        font-size: $font__size--xxl\@mobile;
        color: $color__accent;
        font-weight: normal;
        text-align: center;
    }

    &__subtitle {
        clear: both;
        font-size: $font__size--m\@mobile;
        color: $color__base;
        margin-bottom: $spacer__m\@mobile;
        text-align: center;
    }

    &__nav {
        display: flex;
        list-style-type: none;
        padding: 0;
        justify-content: center;

        &-item {
            margin: 0 $spacer__m\@mobile 0 0;
            padding: 0;
        }
        &-link {
            padding: 6px 10px;
            display: flex;
            background: $color__light-green;
            color: $color__marleen;

            &:hover, &:focus {
                text-decoration: none;
                color: $color__marleen;
            }

            &-icon {
                height: 1.1em;
                width: auto;
                display: inline-block;
                fill: $color__marleen;
                margin-right: 0.5em;
            }
        }
        &-item--active &-link {
            background: $color__marleen;
            color: $color__white;

            &:hover, &:focus {
                color: $color__white;
            }

            &-icon {
                fill: $color__white;
            }
        }
    }

    &__mugshots {
        margin: $spacer__l\@mobile 0 0 0;
        width: 100%;
        overflow-x: auto;

        /*
        &-crew {
            display: none;

            &--active {
                display: block;
            }
        }
        */

        &-row {
            display: flex;
            margin: 0 0 $spacer__m\@mobile 0;
            padding: 0;
            list-style-type: none;

            &:nth-child(odd) {
                margin-left: $spacer__l\@mobile;
            }
        }

        &-item {
            margin: 0 $spacer__m\@mobile 0 0;
            padding: 0;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                object-position: center top;
                max-width: none;
            }

            &-label {
                background: $color__white;
                padding: 8px 0;
                text-align: center;
                color: $color__base;
            }
        }
    }

    &__cta {
        text-align: center;
        padding-top: $spacer__l\@mobile;

        &-button {
            color: $color__accent;
            text-decoration: underline;
        }
    }

    @include mq($screen-l) {
        background: $color__light-green;
        margin: $spacer__xxxl\@desktop 0;
        padding: $spacer__xl\@desktop calc($outdent / 2);

        &__head {
            position: relative;
        }
        &__title, h2 {
            float: left;
            font-size: $font__size--xxl\@desktop;
            text-align: left;
        }
        &__subtitle {
            text-align: left;
        }
        &__nav {
            position: absolute;
            top: 40px;
            right: 0;

            &-item:last-child {
                margin-right: 0;
            }

            &-link {
                background: none;
                padding: 0 12px;
                color: $color__accent;
                opacity: 0.41;

                &:hover, &:focus {
                    color: $color__accent;
                    opacity: 1;
                    outline: 0;
                }

                &-icon {
                    fill: $color__accent;
                    height: 1.3em;
                }
            }

            &-item--active &-link {
                background: none;
                opacity: 1;
                color: $color__accent;

                &:hover, &:focus {
                    color: $color__accent;
                }

                &-icon {
                    fill: $color__accent;
                }
            }
        }

        &__mugshots {
            &-item {
                margin: 0 $spacer__m\@desktop 0 0;

                img {
                    width: 133px;
                    height: 133px;
                }

                &-label {
                    background: $color__white;
                    font-size: $font__size--s\@desktop;
                    padding: 10px 0;
                }
            }
        }

        &__cta {
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: -30px;
            padding: 0;
            left: 0;

            &-button {
                @include cta-button($color: $color__accent);
            }
        }
    }

    // Reset the negative margin if the viewport is not wide enough
    @include mq($container-xl + $outdent * 2) {
        margin: $spacer__xxxl\@desktop (-1 * $outdent);
        padding: $spacer__xl\@desktop $outdent;
    }
}


.about-gallery {
    margin: $spacer__xxxl\@mobile 0;
    position: relative;

    &__text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: $spacer__m\@mobile;
        margin: 0 5%;
        width: 90%;
        background: $color__white;
        color: $color__base;
        font-size: $font__size--m\@mobile;
        text-align: center;
        line-height: 1.4em;

        a {
            font-weight: bold;
        }
    }
    &__title {
        display: none;
    }
    &__photos {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &-row {
            margin: 0;
            padding: 0;
            display: flex;
        }
    }
    &__photo {
        img {
            max-width: 100%;
            height: auto;
        }

        &:nth-child(3) {
            display: none;
        }
    }

    @include mq($screen-s) { // 560px
        &__photo {
            &:nth-child(3) {
                display: block;
            }
        }
    }

    @include mq($screen-l) {
        margin: $spacer__xxxl\@desktop 0;
        display: flex;

        &__text {
            position: static;
            width: 417px;
            background: $color__marleen;
            padding: 81px 67px;
            margin: 0;
            transform: none;
            text-align: left;
            color: $color__base--light;
            font-size: $font__size--m\@desktop;
            box-sizing: content-box;
            max-width: 220px;

            a {
                color: inherit;
            }
        }

        &__title, h2 {
            @include reset-h2();

            position: relative;
            display: block;
            font-size: $font__size--xxl\@desktop;
            font-weight: normal;
            padding-bottom: $spacer__l\@desktop;
            margin-bottom: $spacer__l\@desktop;
            color: $color__base--light;

            &::after {
                display: block;
                border-bottom: 1px solid $color__white;
                width: 50%;
                content: '';
                bottom: 0;
                position: absolute;
                opacity: 0.6;
            }
        }

        &__photo {
            &:nth-child(3) {
                display: block;
            }
        }
    }
}

/*
.about-food {
    margin: $spacer__xxxl\@mobile 0;

    &__text {
        padding: 0 0 $spacer__xl\@mobile;
        font-size: $font__size--m\@mobile;
        text-align: center;

        p {
            line-height: 1.5em;
            margin-bottom: 1.5em;
            color: $color__base;
        }

        &__button {
            @include cta-button();
        }
    }

    &__title, h2 {
        @include reset-h2();

        font-size: $font__size--xl\@mobile;
        font-weight: 600;
        color: $color__accent;
        margin-bottom: $spacer__s\@mobile;
    }

    @include mq($screen-l) {
        margin: $spacer__xxxl\@desktop 0;
        display: flex;

        &__text {
            flex: none;
            width: 448px;
            font-size: $font__size--m\@desktop;
            padding: 150px 0 0;

            & &__delivery {
                font-style: italic;
                color: $color__marleen;
            }
        }

        &__title, h2 {
            font-size: $font__size--xl\@desktop;
        }
    }
}
*/

.about-cta {
    margin: $spacer__xxxl\@mobile 0;
    text-align: center;

    &__button {
        @include cta-button();
    }

    @include mq($screen-l) {
        margin: $spacer__xxxl\@desktop 0;
    }
}
