.cms-zo-werkt-het {
    .zwh-steps {
        margin-top: $spacer;

        &__step {
            position: relative;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
            font-size: $font-size-medium;
            padding: 30px 20px;
            background: $bg-color-base;
            flex-basis: auto;
            text-align: center;
            width: calc(100% - 20px);
            margin: 40px 10px 30px;

            h2 {
                font-size: 23px;
                font-weight: bold;
                color: $color-brand;
                line-height: 1.5em;
                text-align: center;
                margin: 20px 0 7px 0;
                font-style: normal;

                .subtitle {
                    display: block;
                    color: $color-brand-accent;
                    font-size: 21px;
                }
            }

            p::before {
                content: '•';
                display: block;
                color: $color-brand;
                margin-bottom: 7px;
            }

            &--1::before {
                content: url('#{$path-images}cms/zowerkthet-step1.svg');
                position: absolute;
                display: block;
                top: -33px;
                left: calc(50% - 27px);
                width: 55px;
            }
            &--2::before {
                content: url('#{$path-images}cms/zowerkthet-step2.svg');
                position: absolute;
                display: block;
                top: -27px;
                left: calc(50% - 42px);
            }
            &--3::before {
                content: url('#{$path-images}cms/zowerkthet-step3.svg');
                position: absolute;
                transform: rotate(-17deg);
                display: block;
                top: -32px;
                left: calc(50% - 26px);
            }
        }

        @include mq($screen-m) {
            margin-top: $spacer--super-extra-large;

            &__step {
                width: calc(33.3% - 30px);
                margin: 0 15px;

                &--1::before {
                    content: url('#{$path-images}cms/zowerkthet-step1-large.svg');
                    top: -121px;
                    left: 164px;
                    width: auto;
                }
            }
        }
    }
}
