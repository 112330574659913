.slider-control {
    width: $slider-control__size\@mobile;
    height: $slider-control__size\@mobile;
    // background: $color__white;
    // border-radius: 50%;
    display: block;
    cursor: pointer;

    &--previous {
        background: url('#{$path-images}circle-prev.svg') center center no-repeat;
        background-size: 100%;
    }
    &--next {
        background: url('#{$path-images}circle-next.svg') center center no-repeat;
        background-size: 100%;
    }

    @include mq($screen-l) {
        width: $slider-control__size\@desktop;
        height: $slider-control__size\@desktop;
    }
}

.gallery-slider {
    position: relative;
    overflow: hidden;

    img {
        max-width: 100%;
        height: auto;
    }

    .slider-control {
        position: absolute;
        top: 50%;
        margin-top: calc(-1 * $slider-control__size\@mobile / 2);
        z-index: 10;

        &--previous {
            left: $spacer__s\@mobile;
            right: auto;
        }
        &--next {
            right: $spacer__s\@mobile;
            left: auto;
        }
    }

    &__slides {
        display: flex;
        position: relative;
        transition-property: transform;
        width: 100%;
        height: 100%;
        z-index: 1;
        box-sizing: content-box;

        &-slide {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            transition-property: transform;
        }
    }

    @include mq($screen-l) {
        .slider-control {
            &--previous {
                // left: -1 * $slider-control__size\@desktop / 2;
                left: $spacer__m\@desktop;
            }
            &--next {
                // right: -1 * $slider-control__size\@desktop / 2;
                right: $spacer__m\@desktop;
            }
        }

        &__slides {
            &-slide {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.swiper {
    &-button-disabled {
        opacity: 0.6;
    }
}

.diamond-intro {
    position: relative;

    &::after {
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: -0.5 * $diamond__height--mobile;
        height: $diamond__height--mobile;
        background: url('#{$shared__media-url}/diamond-intro.svg') repeat-x center left;
        background-size: $diamond__width--mobile $diamond__height--mobile;
        content: '';
        display: block;
        width: 100%;
    }
    @include mq($screen-l) { // + 1
        &::after {
            bottom: -0.5 * $diamond__height--desktop;
            height: $diamond__height--desktop;
            background-size: $diamond__width--desktop $diamond__height--desktop;
        }
    }
}

.diamond {
    position: relative;

    &::after, &::before {
        z-index: 2;
        position: absolute;
        left: 0;
        height: $diamond__height--mobile;
        background: url('#{$shared__media-url}/diamond-white.svg') repeat-x center left;
        background-size: $diamond__width--mobile $diamond__height--mobile;
        content: '';
        display: block;
        width: 100%;
    }
    &::after {
        bottom: -0.5 * $diamond__height--mobile;
    }
    &::before {
        top: -0.5 * $diamond__height--mobile;
    }

    &--gray {
        &::after, &::before {
            background-image: url('#{$shared__media-url}/diamond-gray.svg');
        }
    }

    @include mq($screen-s) { // 560px
        &::after, &::before {
            height: $diamond__height--desktop;
            background-size: $diamond__width--desktop $diamond__height--desktop;
        }
        &::after {
            bottom: -0.5 * $diamond__height--desktop;
        }
        &::before {
            top: -0.5 * $diamond__height--desktop;
        }

        &--mobile-only {
            &::after, &::before {
                display: none;
            }
        }
    }
}

.action-link {
    display: inline-block;

    &::before {
        content: '› ';
    }
}

.action-link--button-desktop {
    @include mq($screen-l) {
        @include action-button();

        font-size: 16px;
        line-height: 40px;
        min-width: 200px;

        &::before {
            display: none;
        }

        &:hover {
            color: white;
        }
    }
}
