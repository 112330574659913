.cms-quotes {
    $_outdent: 60px;
    margin: $spacer__xxxl\@mobile 0;
    padding: $spacer__xl\@mobile 0;
    background: url('#{$about__media-url}/quotes-back.jpg') center center no-repeat;
    background-size: cover;
    background-origin: border-box;
    overflow: hidden;

    .webp & {
        background: url('#{$about__media-url}/quotes-back.webp') center center no-repeat;
    }

    &__slider {
        display: flex;
        transition-property: transform;
        position: relative;
        width: 100%;
    }

    &__quote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: none;
        background: url('#{$about__media-url}/quotes-back2.svg') center center no-repeat;
        background-size: 100% 100%;
        width: 380px * 0.75;
        height: 400px * 0.75;
        font-size: $font__size--m\@mobile;
        color: $color__accent;
        padding: 40px 20px 15px;
        margin: 0 $spacer__m\@mobile 0 $spacer__m\@mobile;
        line-height: 1.5em;
        text-align: center;
        border: none;

        &-cite {
            font-style: italic;

            img {
                max-height: 40px;
                max-width: 160px;
                margin: 10px auto 0;
                width: auto;
            }
        }
    }

    &__action-link {
        display: block;
        width: 200px;
        max-width: 100%;
        color: $color__white;
        font-size: $font__size--m\@mobile;
        background: rgba(0,0,0, 0.3);
        padding: 5px 10px;
        margin: 30px auto 0;

        &:hover {
            color: $color__white;
        }
    }

    @include mq($screen-l) {
        margin: $spacer__xxxl\@desktop 0;
        padding: $spacer__xl\@desktop calc($_outdent / 2);

        &__slider {
            justify-content: space-between;
            width: auto;
        }

        &__quote {
            width: 380px * 0.90;
            height: 400px * 0.90;
            font-size: $font__size--m\@desktop;
            padding: 50px 30px 20px;
        }
        &__read-all {
            margin: $spacer__m\@desktop 0 0;
            font-size: $font__size--m\@desktop;
        }
    }

    // Reset the negative margin if the viewport is not wide enough
    @include mq($container-xl + $_outdent * 2) {
        margin: $spacer__xxxl\@desktop (-1 * $_outdent) $spacer__xxxl\@desktop;
        padding: $spacer__xl\@desktop $_outdent;
    }
}

.cms-newsletter {
    .widget-c13 {
        padding-bottom: $spacer__l\@mobile;

        .input-group {
            display: block;
            justify-content: center;

            .button {
                width: 200px;
                margin: $spacer__m\@mobile auto 0;
                height: 45px;
                font-weight: normal;
                font-size: $font__size--m\@mobile * 0.9;
            }

            .input-text {
                display: block;
                margin: $spacer__l\@mobile auto $spacer__m\@mobile;
                height: 45px;
            }
        }

        h2 {
            font-size: $font__size--xl\@mobile;
            font-weight: normal;
            margin: 0 0 $spacer__m\@mobile;
        }

    }

    @include mq($screen-l) {
        .widget-c13 {
            padding-bottom: 50px;

            .input-group {
                display: flex;
                justify-content: center;

                .button {
                    height: 55px;
                    padding-left: 45px;
                    padding-right: 55px;
                    font-size: $font__size--m\@desktop * 0.9;
                    margin: 0;
                }

                .input-text {
                    width: 350px;
                    height: 55px;
                    border-right: none;
                    margin: 0;
                }
            }

            h2 {
                font-size: $font__size--xl\@desktop;
                margin: 0 0 $spacer__m\@desktop;
            }
        }
    }
}
