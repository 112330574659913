// H2 is styled a little stupid on the root level
@mixin reset-h2() {
    text-shadow: none;
    font-style: normal;
    margin: 0;
    text-align: inherit;
    line-height: 1.3em;
}

@mixin cta-button($color: $color__marleen) {
    background: transparent no-repeat 0 0;

    @include action-button-back(350, 60, $color);
    background-size: 100% 100%;
    font-size: $font__size--m\@mobile;
    color: $color__white;
    padding: 17px 70px;
    text-decoration: none;
    display: inline-block;

    &:hover {
        color: $color__white;
        text-decoration: underline;
    }

    @include mq($screen-l) {
        font-size: $font__size--m\@desktop;
    }
}

// Break out of the container to a maximum of 1920px
@mixin desktop-breakout() {
    // From the container + 1: set a negative margin to touch the viewport border
    @include mq($container-xl + 1) {
        // Set a negative margin to the amount that the image needs to exceed the width of the container
        margin: 0 calc(-1 * (100vw - #{$container-xl}) / 2) $spacer__l\@desktop;
    }

    // Stop scaling at 1920px
    @include mq($screen-hd) {
        margin-left: -1 * calc(($screen-hd - $container-xl) / 2);
        margin-right: -1 * calc(($screen-hd - $container-xl) / 2);
    }
/*
    // Compensate for .container margin
    @include mq($min-screen: $screen-l, $max-screen: $container-xl) {
        margin-left: -1 * $container__margin\@large;
        margin-right: -1 * $container__margin\@large;
    }

    // Compensate for .container margin
    @include mq($screen-m) {
        margin-left: -1 * $container__margin\@medium;
        margin-right: -1 * $container__margin\@medium;
    }

 */
}

@mixin action-button($color: $color-brand, $hover-color: $color-brand-2) {
    @include action-button-back(110, 30, $color);

    background-size: 100% 100%, auto;
    background-clip: padding-box;
    padding: 0 18px 0 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    min-height: 34px;
    line-height: 34px;
    border: 0;
    font-style: normal;

    &:hover {
        @include action-button-back(110, 30, $hover-color);
        text-decoration: none;
    }
}
