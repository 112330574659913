$side-padding: 15px;
$full-width-fill-bg: #eee;
$desktop-gutter: 30px;
$path-media: '/media';
$heading-line-height: 1.2;

@mixin widget-box() {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin-bottom: 20px;
    padding: $side-padding;

    @include mq($screen-l) {
        padding: 30px;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin full-width-desktop() {
    @include mq($max-content-width) {
        margin: 0 calc(-1 * (100vw - #{$max-content-width} - 22px) / 2);
    }

    // Stop scaling at 1920px
    @include mq($screen-hd) {
        margin-left: -1 * calc(($screen-hd - $max-content-width) / 2);
        margin-right: -1 * calc(($screen-hd - $max-content-width) / 2);
    }
}

.widget-box {
    @include widget-box();
}

.marleen-landing {
    p {
        margin: 0;
    }
}

%widget-subtitle {
    color: $color-brand-accent;
    font-size: 25px;
    line-height: 1.3em;
    font-weight: normal;
    font-style: normal;
    margin: 0 0 15px;
    text-shadow: none;
    text-align: inherit;
}

/* Not in use; template and block classes have also not been migrated to M2

.widget-c0 {
    margin: 30px 0;

    h1 {
        display: inline-block;
        color: $color-brand-accent;
        font-size: 25px;
        padding: 0;
        line-height: $heading-line-height;
    }

    .newsletter-signup {
        // footer .newsletter-signup;
        // @extend footer .newsletter-signup; /////////////////////////////////////////////////////////////////////////////////////
        //&:extend(footer .newsletter-signup);
        display: none;
    }

    @include mq($screen-l) {
        h1 {
            margin: 15px 0;
            line-height: 70px;
            font-size: 40px;
        }

        .newsletter-signup {
            // footer .newsletter-signup;
            //&:extend(footer .newsletter-signup all);
            background: none;
            float: right;
            margin: 10px 0 0;

            &::before {
                top: auto;
            }

            .input-text {
                width: calc(100% - 40px);
            }

            .button {
                width: 40px;
                overflow: hidden;
                padding: 0;
            }

            label {
                color: $color-brand-accent;
            }
        }
    }
}
*/

.widget-c1 {
    background: $full-width-fill-bg url('#{$path-media}/assets/menu-top-desktop.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 -15px 30px;

    &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
    }

    .container {
        position: relative;
        min-height: 360px;
    }

    h1 {
        font-size: 32px;
        color: white;
        margin: 0;
        position: absolute;
        bottom: 60px;
        text-align: center;
        width: 100%;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
        padding: 0 15px;
        line-height: $heading-line-height;
    }

    .sticker {
        $size: 130px;
        width: $size;
        height: $size;
        border-radius: calc($size / 2);
        font-size: 15px;
        padding-top: 34px;
        top: 30px;
        left: 15px;

        transform: rotate(-4.6deg);
        background: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        color: $color-brand-accent;
        position: absolute;

        p {
            margin: 0;
        }

        em {
            color: $color-brand;
            display: inline-block;
            margin-top: 0.2em;
        }
    }

    @include full-width-desktop();

    @include mq($screen-l) {
        h1 {
            font-size: 50px;
        }

        .container {
            height: 470px;
        }

        .sticker {
            $size: 160px;
            width: $size;
            height: $size;
            border-radius: calc($size / 2);
            font-size: 18px;
            padding-top: 40px;
            top: 75px;
        }
    }
}

.widget-c2 {
    background: $full-width-fill-bg url('#{$path-media}/assets/menu-top-desktop.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 -15px 30px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 200px;

    &:before {
        position: absolute;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 100%);
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .container {
        position: relative;
        padding: 0 10px 10px;
    }

    h1 {
        font-size: 25px;
        text-shadow: 0 0 6px rgba(255, 255, 255, 0.9);
        font-style: normal;
        color: #111111;
        margin: 0 0 15px;
        line-height: $heading-line-height;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        padding: 10px;
    }

    .subtitle {
        font-size: 18px;
        max-width: 700px;
        line-height: 1.5em;
        margin: 0 auto 1em;
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.7);
    }

    &.background-center {
        background-position: center center;
    }

    &.style-white {
        min-height: 300px;
        align-items: flex-end;

        &::before {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%);
        }

        .container {
            padding: 0 10px 10px;
        }

        h1 {
            color: white;
            font-size: 32px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
            background: none;
            padding: 0;
        }

        .subtitle {
            color: white;
            font-size: 24px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
        }
    }

    @include full-width-desktop();

    @include mq($screen-l) {
        height: 400px;

        .container {
            padding-bottom: 30px;
        }

        h1 {
            font-size: 40px;
            padding: 20px;
        }

        .subtitle {
            font-size: 22px;
        }

        &.style-white {
            height: 500px;

            h1 {
                font-size: 80px;
                text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.7);
                padding: 0;
            }

            .subtitle {
                font-size: 40px;
                text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
            }
        }
        &.style-large {
            height: 650px;
        }
    }
}

.widget-c3 {
    padding: 30px 0;
    margin: 30px 0;
    font-size: 16px;
    line-height: 1.5em;
    @include content-block-small();

    h2 {
        font-size: 25px;
        line-height: 1.4;
        font-style: normal;
        color: $color-brand-accent;
        margin: 15px 0;
        text-align: left;
    }

    .content-image {
        height: 190px;
        background-size: cover;
    }

    @include mq($screen-l) {
        //@extend .widget-box; ////////////////////////////////////////////////////////////////////////////////////////////////
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        padding: 30px;

        h2 {
            font-size: 28px;
            margin: 0 0 30px;
        }

        .content-image {
            flex: calc(50% - 30px) 0 0;
            order: 1;
            height: 300px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .content {
            flex: calc(50% - 30px) 0 0;
            order: 2;
        }

        &.image-align-right {
            .content-image {
                order: 2;
            }
            .content {
                order: 1;
            }
        }
    }
}

.widget-c5 {
    margin: 30px 0;

    .column {
        @include widget-box();
        margin-top: 65px;
        padding: 45px 30px;
        text-align: center;
        font-size: 18px;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
        color: $color-brand-accent;
        line-height: 1.5em;
        margin-top: 20px;

        span {
            color: $color-brand;
            display: block;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 0;
            margin-bottom: 7px;

            &::before {
                content: '•';
                display: block;
                color: $color-brand;
                margin-bottom: 7px;
            }
        }
    }

    .icon {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 60px;
        width: auto;
        margin-top: -65px;
        margin-bottom: 20px;
    }

    @include mq($screen-l) {
        $gutter: 30px;
        display: flex;
        margin-left: calc(-1 * $gutter / 2);
        margin-right: calc(-1 * $gutter / 2);

        .column {
            flex-basis: calc(33.333% - #{$gutter});
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.widget-c6 {
    background: $full-width-fill-bg top center no-repeat;
    background-size: cover;
    margin: 60px -15px;

    .container {
        height: 360px;
    }

    @include full-width-desktop();

    @include mq($screen-l) {
        .container {
            height: 420px;
        }
    }
}

.widget-c8 {
    @extend .widget-c2; // left out 'all' from Less

    margin: 60px -15px;

    h2 {
        // &:extend(.widget-c2 h1); ////////////////////////////////////////////////////////////////////////////////////////
    }

    @include mq($screen-l) {
    }
}

.widget-c9 {
    margin: 0 0 60px;

    .column {
        margin-top: 30px;
        padding: 0;
        font-size: 16px;
        line-height: 28px;

        h2 {
            color: $color-brand-accent;
            font-size: 22px;
            font-weight: normal;
            font-style: normal;
            margin-bottom: 10px;
            margin-top: 0;
            text-shadow: none;
            text-align: left;
            line-height: 30px;
        }
    }

    .icon {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        float: left;
        height: 30px;
        width: 40px;
        margin-right: 10px;
    }

    @include mq($screen-l) {
        $gutter: 30px;
        display: flex;
        margin-top: 50px;
        margin-bottom: 50px;

        .column {
            flex: 1 33.332%;
            margin-left: calc($gutter / 2);
            margin-right: calc($gutter / 2);
            margin-top: 0;
            font-size: 18px;

            h2 {
                font-size: 25px;
                margin-bottom: 15px;
                margin-top: 45px;
                line-height: 1.3em;
            }

            p {
                padding-right: 15px;
            }
        }
    }
}

.widget-c11 {
    @extend .widget-box;
    padding: 5px;
    margin: 60px 0;

    .video-container {
        position:relative;
        padding-bottom:56.25%;
        height:0;
        overflow:hidden;
    }

    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    @include mq($screen-l) {
        padding: 10px;
    }
}

.widget-c12 {
    margin-top: 60px;
    margin-bottom: 60px;

    .landing-quotes {
        // The parent already has the correct margins
        margin-top: 0;
        margin-bottom: 30px;
    }

    h2 {
        text-align: center;
    }

    .reviews {
        [class*='col-'] {
            padding-left: 15px;
            padding-right: 15px;
        }

        .review {
            background-color: white;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
            margin-bottom: 30px;
            padding: $spacer--semi-medium;
            font-size: $font-size-base;
            line-height: 1.5em;

            .author {
                font-style: italic;
                font-weight: bold;
                margin: 1em 0 0 0;
            }
            .date {
                font-style: italic;
                color: $color-secondary;
                margin: 0;
            }
        }
    }

    .show-more {
        text-align: center;

        span {
            color: $color-brand-accent;
            font-weight: bold;
            display: block;
        }

        a {
            $size: 45px;
            margin-top: $spacer;
            width: $size;
            height: $size;
            background: #fff url('#{$path-images}arrow-down-green.svg') no-repeat center 55%;
            background-size: 19px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
        }
    }

    @include mq($screen-l) {
        .landing-quotes {
            // The parent already has the correct margins
            margin-top: 0;
            margin-bottom: 45px;
        }
    }
}


.widget-c13 {
    background: $bg-color-brand;
    padding: 50px 30px 30px;
    text-align: center;
    position: relative;
    margin: 90px 0 30px;

    &::before {
        display: block;
        position: absolute;
        content: '';
        top: -55px;
        left: 0;
        width: 100%;
        height: 100px;
        background: url('#{$path-images}footer-newsletter.svg') no-repeat center 0;
        background-size: contain;
    }

    h2 {
        @extend %widget-subtitle;
    }
    .input-group {
        .input__field {
            width: 90%;
            display: inline-block;
        }

        .button {
            display: block;
            margin: 17px auto 0;
        }
    }

    @include mq($screen-l) {
        h2 {
            font-size: 30px;
        }

        .input-group {
            .input__field {
                width: 250px;
            }

            .button {
                display: inline-block;
                margin: 0;
            }
        }
    }
}

.widget-c14 {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
        @extend %widget-subtitle;
        text-align: center;
    }

    .product-list {
        @include clearfix();
    }

    @include mq($screen-l) {
        h2 {
            font-size: 34px;
        }
    }
}

.widget-c15 {
    text-align: center;
    margin: 60px 0 30px;

    h2,
    .subtitle {
        @extend %widget-subtitle;
        font-size: 25px;
        line-height: 1.4em;
    }

    .subtitle {
        font-size: 18px;
        line-height: 1.5em;
    }

    @include mq($screen-l) {
        h2, .subtitle {
            font-size: 34px;
        }

        .subtitle {
            font-size: 22px;
        }
    }
}

.widget-c17 {
    text-align: center;
    margin: 15px 0 30px;
}

.widget-c19 {
    text-align: center;
    margin: 30px 0;

    p {
        color: $color-brand-accent;
        font-size: 18px;
        display: inline-block;
        padding-left: 94px;
        line-height: 40px;

        background: url('#{$path-images}bike-green.svg') no-repeat 0 center;
        background-size: auto 37px;
    }
}

.widget-giftcard {
    .content-image {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .buy-giftcard {
        margin-top: 15px;
    }

    @include mq($screen-l) {
        h2 {
            margin-top: 0;
        }
        .content-image {
            background-size: contain;
        }
    }
}

@include mq($screen-l) {
    .widget-c5,
    .widget-c9 {
        .icon {
            height: 60px;
            width: auto;
            margin-right: 0;
            float: none;
        }
    }
}
