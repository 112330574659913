@import '../../Atoms/lib/sassdash/index';
@import '../../Atoms/lib/sass-svg/index';

@mixin action-button-back($width, $height, $color: $color-brand) {
    $width-less: $width - 5;
    $height-less: $height - 1;
    @include svg ((
            width: $width,
            height: $height,
            preserveAspectRatio: none
    )) {
        @include svg('path', (
                fill: $color,
                d: "M1,0 L#{$width},0 L#{$width-less},#{$height} L1,#{$height-less} L1,0",
        ));
    }
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
